let env;
let backendHost;
let tokenHost;
let rftClient;
let orderClient;
let carrierClient;
let trackingHost;
let loginHome;
const apiVersion = 'v1';
const context = '/innovel-services/login/api';
const tokenContext = '/innovel-services/login/api';
const trackingContext = '';
const orderContext= '/innovel-services/order';
const rftContext= '/innovel-services/rft';
const carrierContext = '/innovel-services/carrier';
env = process.env.REACT_APP_RFT_ENV  || 'local'

switch (env) {
  case 'local':
    backendHost = '';
    tokenHost = '';
    rftClient = '';
    orderClient = '';
    carrierClient = '';
    trackingHost = '';
    loginHome = '/innovel-services/login/';
    break;
  case 'dev':
     backendHost = 'http://10.248.118.207:8080';
    tokenHost = 'http://10.248.118.207:8080';
    rftClient = 'http://10.248.118.41';
    orderClient = 'http://10.248.118.40';
    carrierClient = 'http://10.248.118.48';
    trackingHost = 'http://10.248.118.211:8080';
    loginHome = 'http://10.248.118.50';
    break;
  case 'nonprod':
    backendHost = '';
    tokenHost = '';
    rftClient = '';
    orderClient = '';
    carrierClient = '';
    trackingHost = '';
    loginHome = '/innovel-services/login/';
    break;
  case 'prod':
    backendHost = '';
    tokenHost = '';
    rftClient = '';
    orderClient = '';
    carrierClient = '';
    trackingHost = '';
    loginHome = '/innovel-services/login/';
    break;
  default:
    backendHost = 'http://localhost:8085';
    tokenHost = 'http://localhost:8085';
    rftClient = 'http://localhost:3003';
    orderClient = 'http://localhost:3004';
    carrierClient = 'http://localhost:3008';
    trackingHost = 'http://localhost:3000';
    loginHome = 'http://localhost:3005';
}

export const API_ROOT = backendHost + context;
export const API_SERVER = backendHost + '/innovel-services';
export const TOKEN_ROOT = tokenHost + tokenContext;
export const APP_CONTEXT = '/innovel-services/order';
export const ORDER_ROOT = orderClient + orderContext;
export const RFT_ROOT = rftClient + rftContext;
export const CARRIER_ROOT = carrierClient + carrierContext;
export const TRACKING_ROOT = trackingHost + trackingContext;

export const LOGIN_ROOT = loginHome;
